/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyB5mcVrgagROUEuwTquO7fo_BqHxJKKesg",
  "appId": "1:539801733837:web:68f9076954835a24c07784",
  "authDomain": "schooldog-i-union-ga.firebaseapp.com",
  "measurementId": "G-065PGRQHJC",
  "messagingSenderId": "539801733837",
  "project": "schooldog-i-union-ga",
  "projectId": "schooldog-i-union-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-union-ga.appspot.com"
}
